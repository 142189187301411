<template>
  <div class="modal-form">
    <h3 class="card-title">Editar sucursal</h3>
    <form @submit.prevent="save">
      <pd-input
        v-model="descripcion"
        id="descripcion"
        label="Descripcion"
        placeholder="Ingrese el nombre de la sucursal"
        class="my-1"
        :required="enviado && !$v.descripcion.required"
      ></pd-input>
      <button class="btn btn-right btn-primary">Guardar</button>
    </form>
  </div>
</template>
<script>
import { SucursalServices } from "../services/SucursalServices";
import Swal from "sweetalert2";
export default {
  name: "EditarSucursal",
  data() {
    return {
      descripcion: "",
      enviado: false
    };
  },
  props: {
    sucursal: null,
  },
  validations:{
    ...SucursalServices.validations
  },
  mounted() {
    this.descripcion = ""+{...this.sucursal}.descripcion;
  },
  methods: {
    save() {
      SucursalServices.api.put(this.sucursal.id,{
        descripcion: this.descripcion,
      })
        .then((r) => {
          Swal.fire(
            "Éxito",
            `<p><b>${r.sucursal.descripcion}</b> se ha guardado con éxito</p>`,
            "success"
          );
          this.$emit("saved");
        })
        .catch((e) => {
          Swal.fire("Error", `<p>${e}</p>`, "error");
        });
    },
  },
};
</script>
<template>
  <div class="card">
    <h3 class="card-title">Visualizar sucursal</h3>
    <template v-if="sucursal">
      <h4 class="">
        <span class="text-secondary">Descripción:</span>
        {{ sucursal.descripcion }}
      </h4>
      <div class="d-flex space-between">
        <button
          class="btn btn-info"
          @click="$router.push({ name: 'ListarSucursales' })"
        >
          Volver
        </button>
        <div>
          <button class="btn btn-warning" @click="openEdit = true">
            Editar sucursal
          </button>
          <button class="btn btn-error" @click="eliminarSucursal">
            Eliminar sucursal
          </button>
        </div>
      </div>
      <modal v-if="openEdit" @close="openEdit = false">
        <editar-sucursal
          slot="modal-body"
          :sucursal="sucursal"
          @saved="saved"
        ></editar-sucursal>
      </modal>
    </template>
  </div>
</template>
<script>
import { SucursalServices } from "../services/SucursalServices";
import Swal from "sweetalert2";
import Modal from "@/components/elements/Modal";
import EditarSucursal from "./EditarSucursal.vue";
export default {
  name: "VerSucursal",
  components: {
    Modal,
    EditarSucursal,
  },
  data() {
    return {
      sucursal: null,
      openEdit: false,
    };
  },
  mounted() {
    SucursalServices.api.find(this.$route.params.id).then((response) => {
      this.sucursal = response.sucursal;
    });
  },
  methods: {
    eliminarSucursal() {
      Swal.fire({
        title: "Confirme",
        html: `<p>Está seguro que desea eliminar <b>${this.sucursal.descripcion}</b>?</p>`,
        icon: "question",
        showConfirmButton: true,
        confirmButtonText: "Eliminar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          SucursalServices.api.delete(this.sucursal).then(() => {
            Swal.fire("Éxito", "<p>Se ha eliminado con éxito</p>", "success");
            this.$router.push({ name: "ListarSucursales" });
          });
        }
      });
    },
    saved() {
      this.openEdit = false;
      SucursalServices.api.find(this.sucursal.id).then((response) => {
        this.sucursal = response.sucursal;
      });
    },
  },
};
</script>